import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./components/Header";
import About from "./components/About";
import Services from "./components/Services";
import PhotoGallery from "./components/PhotoGallery";
import VideoGallery from "./components/VideoGallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div id="home" className="App">
      <div className="header">
        <Header />
      </div>
      <div id="about" className="about anchor">
        <About />
      </div>
      <div id="services" className="services anchor">
        <Services />
      </div>
      <div id="photo_gallery" className="photo_gallery anchor">
        <PhotoGallery />
      </div>
      <div id="video_gallery" className="video_gallery anchor">
        <VideoGallery />
      </div>
      <div id="contact" className="contact anchor">
        <Contact />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
