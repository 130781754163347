import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import planting from "../images/services/planting.png";
import hardscape from "../images/services/hardscape.png";
import landscape from "../images/services/landscape.png";
import waterscape from "../images/services/waterscape.png";
import fences from "../images/services/fences.png";
import structures from "../images/services/structures.png";

const Services = () => {
  return (
    <Container>
      <h2>Our Services</h2>
      <hr />
      <p>We are providing following services:</p>
      <Row className="services_row">
        <Col>
          <h3>Landscape design</h3>
          <Image className="service_image" src={landscape} rounded />
          <p>
            We can transform your unique vision into reality and create a
            detailed 3D design for both: private and commercial properties.
          </p>
        </Col>
        <Col>
          <h3>Hardscape</h3>
          <Image className="service_image" src={hardscape} rounded />
          <p>
            We can design and build patios, outdoor kitchen islands, and
            walkways that will accommodate all of your needs and bring you
            living enjoyment.{" "}
          </p>
        </Col>
        <Col>
          <h3>Softscaping</h3>
          <Image className="service_image" src={planting} rounded />
          <p>
            We can plant a variety of horticulture depending on your preference,
            including planting beds and sod grass installation.
          </p>
        </Col>
      </Row>
      <Row className="services_row">
        <Col>
          <h3>Waterscaping</h3>
          <Image className="service_image" src={waterscape} rounded />
          <p>
            We can build artificial ponds, fountains, waterfalls, and other
            water features that will make a perfect addition to your design.
          </p>
        </Col>
        <Col>
          <h3>Fences and Decking</h3>
          <Image className="service_image" src={fences} rounded />
          <p>
            We can install different types of fences and decking keeping your property and
            personal needs in mind without any issues.{" "}
          </p>
        </Col>
        <Col>
          <h3>Outdoor structures</h3>
          <Image className="service_image" src={structures} rounded />
          <p>
            We can build and install patio covers, overhangs, pergolas and other
            outdoor structures you might need for a successful outdoor
            time-spending.{" "}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
