import React from "react";

// import Spinner from "./UISpinner";

import { Button, Modal } from "react-bootstrap";

const UIModal = (props) => {
  return (
    <Modal
      className="modal"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={props.backdrop}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} disabled={props.loading}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UIModal;
