import React from "react";

import { Spinner } from "react-bootstrap";

const UILoading = (props) => {
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default UILoading;
