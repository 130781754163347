import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <Container className="h-25">
      <Row>
        <ul className="list-inline justify-content-center">
          <li>
            <SocialIcon
              url="https://www.facebook.com/greenlivingwall.inc"
              network="facebook"
              bgColor="#6aaf08"
            />
          </li>
          <li>
            <SocialIcon
              url="https://www.youtube.com/channel/UCto5tuA44xuIJ3uC8Ev4xxA"
              network="youtube"
              bgColor="#6aaf08"
            />
          </li>
        </ul>
      </Row>
      <Row>
        <Col>
          <p>Phone: +1 (215) 494 6024</p>
          <p>Email: info@greenlivingwall.com</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>&copy; 2021 Green Living Wall Inc.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
