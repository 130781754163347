import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import collage_image from "../images/about/collage.jpg";

const About = () => {
  return (
    <Container>
      <Row className="about_description">
        <Col>
          <h2>
            Welcome to <span>Green Living Wall</span>
          </h2>
          <hr />
          <p>
            Green Living Wall is a locally owned and established business that
            has been serving the Greater Philadelphia area, Delaware and New
            Jersey for over 11 years. Our team is dedicated to providing expert
            landscaping services, while delivering desirable results on time.
          </p>
          <p>
            <b> What we do:</b>
            <br></br>- Landscaping;<br></br>- Landscape lighting; <br></br>-
            Hardscaping; <br></br>- Waterscaping (ponds, fountains, artificial
            waterfalls); <br></br>- Softscaping (living elements of your
            landscape - horticulture); <br></br>- Modeling and recreating
            outdoor living areas;<br></br>
          </p>
          <p>
            <b>
              At Green Living Wall, we use special 3D software, that allows us
              to provide the following:
            </b>
            <br></br> - Plan drawing;<br></br> - 3D Pictures;<br></br> - Virtual
            video tours;<br></br>- Drone services according to FAA regulations;
            <br></br>
          </p>
          <p> And more!</p>
          <p>
            While working with us, you will get a detailed 3D project of your
            new landscaping design, together with high-resolution photographs
            and videos of your property from before and how it would look after
            our work is done. You have a chance to compare and decide if you
            would like to make any changes before starting the project. We take
            pride in delivering the best results for our loyal customers and
            would love to help you with your next project!
          </p>
        </Col>
        <Col>
          {" "}
          <img
            className="collage_image"
            src={collage_image}
            alt="collage_image"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default About;
