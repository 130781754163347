import React from "react";
import { Navbar, Container, Nav, Row, Col, Card } from "react-bootstrap";

import main_image from "../images/main/main_image.jpg";

const Header = () => {
  return (
    <React.Fragment>
      <Navbar collapseOnSelect fixed="top" bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <div className="header_company_name">Green Living Wall</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="header_navbar justify-content-end py-2 ">
              <Nav.Link href="#about" className="header_link_element ">
                About
              </Nav.Link>
              <Nav.Link href="#services" className="header_link_element">
                Services
              </Nav.Link>
              <Nav.Link href="#photo_gallery" className="header_link_element">
                Photo Gallery
              </Nav.Link>
              <Nav.Link href="#video_gallery" className="header_link_element">
                Video Gallery
              </Nav.Link>
              <Nav.Link href="#contact" className="header_link_element">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Row>
        <Col>
          <Card className="bg-dark text-white">
            <Card.Img
              className="header_main_image"
              src={main_image}
              alt="header main image"
            />
            <Card.ImgOverlay className="header_overlay">
              <Card.Title>
                <h1 className="header_overlay_title">LANDSCAPE DESIGN</h1>
              </Card.Title>
              <Card.Text className="header_overlay_description">
                And Construction Services
              </Card.Text>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Header;
