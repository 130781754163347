import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import YoutubeEmbed from "../UIElements/UIYoutubeEmbed";

const VideoGallery = () => {
  return (
    <Container>
      <h2>Our Videos</h2>
      <hr />
      <Row>
        <Col>
          {" "}
          <YoutubeEmbed embedId="cPcykCrcSXw" />
        </Col>
        <Col>
          {" "}
          <YoutubeEmbed embedId="48aZRJFrwbo" />
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col>
          {" "}
          <YoutubeEmbed embedId="ZquuzflhGm0" />
        </Col>
        <Col>
          {" "}
          <YoutubeEmbed embedId="j6KEu-u91h4" />
        </Col>
      </Row>
    </Container>
  );
};

export default VideoGallery;
