import React, { useState } from "react";
import { Carousel, Container, Row } from "react-bootstrap";

import image7 from "../images/gallery/7.jpg";
import image8 from "../images/gallery/8.jpg";
import image9 from "../images/gallery/9.jpg";
import image10 from "../images/gallery/10.jpg";
import image11 from "../images/gallery/11.jpg";
import image12 from "../images/gallery/12.jpg";
import image13 from "../images/gallery/13.jpg";
import image14 from "../images/gallery/14.jpg";

const PhotoGallery = () => {
  function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    return (
      <Container>
        <h2>Photo Gallery</h2>
        <hr />
        <Row>
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <img className="d-block w-100" src={image7} alt="Third slide" />
              <Carousel.Caption>
                <h3>Huntingdon Valley, PA</h3>
                <p>Steps, retaining walls, entry porch, sod and plants.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image8} alt="Third slide" />
              <Carousel.Caption>
                <h3>Warrington, PA</h3>
                <p>Pation space, outdoor kitchen island, patio cover.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image9} alt="Third slide" />
              <Carousel.Caption>
                <h3>Langhorne, PA</h3>
                <p>Walkways, driveway, flower beds, lighting.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image10} alt="Third slide" />
              <Carousel.Caption>
                <h3>Warrington, PA</h3>
                <p>Retaining walls, flower beds, plants.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image11} alt="Third slide" />
              <Carousel.Caption>
                <h3>Newtown, PA</h3>
                <p>New fence, retaining wall, decking.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image12} alt="Third slide" />
              <Carousel.Caption>
                <h3>Philadelphia, PA</h3>
                <p>Drainage system, deck, plants, sod.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image13} alt="Third slide" />
              <Carousel.Caption>
                <h3>Meadowbrook, PA</h3>
                <p>Artificial pond and plants.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={image14} alt="Third slide" />
              <Carousel.Caption>
                <h3>Southampton, PA</h3>
                <p>
                  New patio space (bluestone) with retaining walls and lights.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
    );
  }
  return <ControlledCarousel />;
};

export default PhotoGallery;
