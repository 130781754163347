import React, { useState } from "react";
import { send } from "emailjs-com";

import Spinner from "../UIElements/UISpinner";
import Modal from "../UIElements/UIModal";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Contact = () => {
  const USER_ID = "user_E1Xpp9LhHjMGuRzbr0lnh";
  const SERVICE_ID = "service_da88pmm";
  const TEMPLATE_ID = "template_6n04ggs";
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "greenlivingwall@gmail.com",
    message: "",
    reply_to: "",
  });

  const [response, setResponse] = useState(false);

  const setOkResponse = () => {
    setResponse(true);
  };

  const setErrResponse = () => {
    setResponse(false);
  };

  const [loading, setLoading] = useState(false);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const [modalShow, setModalShow] = useState(false);

  const openModal = () => {
    setModalShow(true);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    startLoading();
    openModal();
    send(SERVICE_ID, TEMPLATE_ID, toSend, USER_ID)
      .then((response) => {
        if (response.status === 200) {
          stopLoading();
          setOkResponse();
        }
      })
      .catch((err) => {
        if (err) {
          stopLoading();
          setErrResponse();
        }
      });
    setToSend({ from_name: "", message: "", reply_to: "" });
  };
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const serverTitleResponse = (param) => {
    switch (param) {
      case false:
        return "Error";

      default:
        return "Succes";
    }
  };
  const serverBodyResponse = (param) => {
    switch (param) {
      case false:
        return "Something went wrong";
      default:
        return "Thanks for reaching us, we will contact you shortly.";
    }
  };

  return (
    <Container>
      <Modal
        show={modalShow}
        onHide={hideModal}
        title={loading ? "Loading" : serverTitleResponse(response)}
        backdrop={loading ? "static" : "true"}
        loading={loading}
        body={loading ? <Spinner /> : serverBodyResponse(response)}
      />
      <h2>LEAVE US A MESSAGE</h2>
      <hr />
      
      <Row className="row justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={onSubmit} className="text-center">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                name="from_name"
                placeholder="Your name"
                value={toSend.from_name}
                onChange={handleChange}
                required="required"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="reply_to"
                placeholder="name@example.com"
                value={toSend.reply_to}
                onChange={handleChange}
                required="required"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Your message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={3}
                value={toSend.message}
                onChange={handleChange}
                required="required"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="message_button justify-content-center">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
